/** @jsx jsx */
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Flex } from 'rebass';
import { Box, jsx } from 'theme-ui';
import { Container } from '../../components/Container';
import { Ul, Li } from '../../components/List';
import { LocalizedLink } from '../../components/LocalizedLink';
import { H1, H2, H3, P } from '../../components/Text';
import { useLocalStorateTzOffset } from '../../components/TimezonePicker';
import { TimeZoneSwitcher } from '../../components/TimeZoneSwitcher';
import { ProgramDetailLayout } from '../../layouts/ProgramDetailLayout';

const LocalLeadersRetreatPage = ({ data }) => {
  const { tzOffset, changeTimeZones } = useLocalStorateTzOffset();

  const headingMargins = { mt: 4, mb: 3 };

  return (
    <ProgramDetailLayout>
      {({ tzOffset }) => (
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              justifyContent: 'space-between',
              alignItems: ['flex-start', 'flex-start', 'center'],
            }}
          >
            <LocalizedLink
              to={'/program/'}
              sx={{ variant: 'variants.link', mb: [3, 3, 0] }}
            >
              ← Back to schedule
            </LocalizedLink>
            <TimeZoneSwitcher
              tzOffset={tzOffset}
              changeTimeZones={changeTimeZones}
            />
          </Flex>
          <H1>Local Leaders Retreat</H1>
          <H2 sx={headingMargins}>1 February 2021</H2>
          <Box sx={{ width: ['100%', '100%', '50%'] }}>
            <P>
              Are you an IxDA Local Leader or interested in starting an IxDA
              Local Group in your city? Come join us for this year’s Local
              Leaders Retreat.
            </P>
            <P>
              Get together with your fellow Local Leaders, our Regional
              Coordinators and the global IxDA Board of Directors to workshop
              strategies to help us all support and advance a passionate IxDA
              community.
            </P>
          </Box>
          <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
            <Flex
              sx={{ flexDirection: 'column', width: ['100%', '100%', '50%'] }}
            >
              {data.allImageSharp.edges.map(e => {
                return (
                  <Box sx={{ p: 4 }}>
                    <Img fluid={e.node.fluid} />
                  </Box>
                );
              })}
            </Flex>
            <Box
              sx={{
                width: ['100%', '100%', '50%'],
                alignSelf: 'flex-end',
              }}
            >
              <H3 sx={headingMargins}>
                One day to talk about the present and the future of the IxDA
                representation around the world
              </H3>
              <P>
                This year, in addition to sharing stories and best practices,
                and making connections with your fellow Local Leaders, we want
                to prompt a broader discussion – grounded in the realities of
                2020 and how it is shaping our future:
              </P>
              <Ul>
                <Li>How has this year changed us?</Li>
                <Li>
                  What is different and how do we build on our new reality?
                </Li>
                <Li>Where do we want to go collectively?</Li>
              </Ul>
              <P>
                Our format will be a series of three 4-hr workshops to
                accommodate our global network, optimized for different
                timezones. Pick one workshop, or sign up for all three sessions.
                Sessions will be recorded so they can be shared.
              </P>
              <P>
                You’ll spend time workshopping strategies and content that may
                incubate new IxDA initiatives to serve the community both
                locally and globally.
              </P>
              <P>
                Come prepared with ideas you’d like to share and open for
                discussion with others.
              </P>
            </Box>
          </Flex>
          <H3 sx={headingMargins}>One day to meet old and new friends</H3>
          <P>
            The goal is for all us is to understand our shared challenges
            created by the pandemic, to develop and validate strategies for the
            future, and to create a common understanding of how our activities
            advance IxDA’s mission and vision, at the local, regional and global
            level.
          </P>
          <P>
            Learn how to overcome them and interact and connect with the other
            local leaders from around the globe.
          </P>
        </Container>
      )}
    </ProgramDetailLayout>
  );
};

export const llImage = graphql`
  query localLeadersImages {
    allImageSharp(filter: { fluid: { originalName: { regex: "/^local/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 900, maxHeight: 559) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default LocalLeadersRetreatPage;
